<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Create Call ({{activeRoute.name}} Route)</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon :to="`/routes/${id}/calls`">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-btn class="mr-1" outlined color="toolbarIcon" text @click="sendData" :loading="loader">
        <v-icon left dark>mdi-content-save</v-icon>Save
      </v-btn>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-row dense>
            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.SlpCode"
                :items="salesEmployees"
                item-text="SlpName"
                item-value="id"
                label="Sales Rep"
              ></v-autocomplete>
            </v-col>

<!--            <v-col cols="3">-->
<!--              <v-text-field-->
<!--                label="Region"-->
<!--                v-model="record.descript"-->
<!--                outlined-->
<!--                dense-->
<!--                @click="openRegionModal"-->
<!--              ></v-text-field>-->
<!--            </v-col>-->

            <v-col cols="3">
              <DatePicker outlined dense @date="setDate" :myDate="record.CallDate" :title="`Date`"></DatePicker>
            </v-col>

            <v-col cols="3">
              <v-autocomplete
                outlined
                dense
                v-model="record.Repeat"
                :items="repeatData"
                item-text="name"
                item-value="value"
                label="Repeat"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="selectedOutlets"
                :items="outlets"
                chips
                @change="findOutlets"
                :search-input.sync="searchInput"
                deletable-chips
                label="Outlets"
                item-text="CardName"
                item-value="CardCode"
                multiple
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-data-table
                loading-text="Loading... Please wait"
                :headers="outletHeaders"
                :items="outletsArray"
                item-key="id"
              >
                <!-- start of start time template -->
                <template v-slot:item.StartTime="{ item }">
                  <TimePicker @time="setTime" :id="item.id" :timing="`start`" :title="`Start Time`"></TimePicker>
                </template>
                <!-- end of start time -->

                <!-- start of end time template -->
                <template v-slot:item.EndTime="{ item }">
                  <TimePicker @time="setTime" :id="item.id" :timing="`end`" :title="`End Time`"></TimePicker>
                </template>
                <!-- end of end time -->
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- start of territories dialog -->
<!--      <v-dialog v-model="regionDialog" width="650px">-->
<!--        <v-card>-->
<!--          <v-toolbar color="accent" dark dense>-->
<!--            <v-toolbar-title>Select Territory</v-toolbar-title>-->
<!--            <v-spacer></v-spacer>-->
<!--            <v-btn icon @click.native="regionDialog = false">-->
<!--              <v-icon>mdi-close</v-icon>-->
<!--            </v-btn>-->
<!--          </v-toolbar>-->
<!--          <v-card-text>-->
<!--            <v-row>-->
<!--              <v-col cols="12">-->
<!--                <v-treeview-->
<!--                  color="accent"-->
<!--                  :activatable="trueValue"-->
<!--                  :hoverable="trueValue"-->
<!--                  :selection-type="selectionType"-->
<!--                  open-all-->
<!--                  return-object-->
<!--                  item-text="descript"-->
<!--                  item-key="id"-->
<!--                  :items="territories"-->
<!--                  item-children="children_recursive"-->
<!--                  :search="search"-->
<!--                >-->
<!--                  <template v-slot:label="{ item }">-->
<!--                    <div @click="selectRegion(item)">-->
<!--                      <span style="font-weight: bold">{{item.descript}}</span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </v-treeview>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
      <!-- end of territories dialog -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    itemsData: {
      type: Object,
    },
    activeRoute: {
      type: Object,
    },
    id: {
      type: String,
    },
    outlets: {
      type: Array,
    },
  },
  data() {
    return {
      record: {},
      loader: false,
      territory: {},
      routeData: {},
      menu2: false,
      menu3: false,
      search: null,
      searchInput: null,
      selectionType: "leaf",
      trueValue: true,
      // regionDialog: false,
      callDialog: false,
      editMode: false,
      loading: false,
      calls: [],
      selectedOutlets: [],
      outletsArray: [],
      territories: [],
      salesEmployees: [],
      repeatData: [
        { name: "Never", value: "N" },
        { name: "Daily", value: "D" },
        { name: "Weekly", value: "W" },
        { name: "Fortnightly", value: "F" },
        { name: "Monthly", value: "M" },
        { name: "Annually", value: "A" },
      ],
      outletHeaders: [
        { text: "Outlet", value: "name" },
        { text: "Start Time", value: "StartTime" },
        { text: "End Time", value: "EndTime" },
      ],
    };
  },
  watch: {
    itemsData: {
      handler: "setRoute",
      immediate: true,
    },
  },
  methods: {
    setRoute(val) {
      this.record = { ...val };
    },
    sendData() {
     const self = this;
      const data = {
        SlpCode: self.record.SlpCode,
        CallDate: self.record.CallDate,
        Repeat: self.record.Repeat,
        outlets: self.outletsArray,
      };
      this.loader = true;
      this.$emit("data", data);
    },
    setTime(data) {
      const outlet = this.outletsArray.find((record) => {
        return record.id === data.id;
      });
      const pos = this.outletsArray.indexOf(outlet);
      if (pos === -1) {
        return;
      }

      if (data.timing == "start") {
        outlet.StartTime = data.time;
      } else {
        outlet.EndTime = data.time;
      }
      // update array
      this.outletsArray[pos] = outlet;
    },
    findOutlets() {
      const selectedOutlets = this.selectedOutlets;
      let outlets = this.outlets;
      let allOutlets = [];
      for (const selectedOutlet of selectedOutlets) {
        const outlet = outlets.find((record) => {
          return record.CardCode === selectedOutlet;
        });
        const outletObject = {
          id: outlet.id,
          CardCode: outlet.CardCode,
          name: outlet.CardName,
          StartTime: null,
          EndTime: null,
        };
        allOutlets.push(outletObject);
      }
      this.outletsArray = allOutlets;
    },
    addCall() {
      this.callDialog = true;
    },
    // openRegionModal() {
    //   this.regionDialog = true;
    // },
    setDate(date) {
      this.record.CallDate = date;
    },
    // selectRegion(data) {
    //   this.territory = data;
    //   const id = data.id;
    //   const self = this;
    //   self.loading = true;
    //   // api call
    //   this.$store
    //     .dispatch("get", `/territories/${id}`)
    //     .then((res) => {
    //       self.outlets = res.outlets;
    //       const recordData = self.record;
    //       self.record = { ...recordData, ...res };
    //       self.loading = false;
    //       self.regionDialog = false;
    //     })
    //     .catch((err) => {
    //       console.log(err, "error");
    //       // this.$refs.snackbar.show(err, "red");
    //     });
    //   // end
    // },
    // getTerritories() {
    //   const self = this;
    //   this.loading = true;
    //   this.$store
    //     .dispatch("get", `/territories`)
    //     .then((res) => {
    //       self.territories = res.ResponseData;
    //       self.loading = false;
    //     })
    //     .catch((err) => {
    //       this.$refs.snackbar.show(err, "red");
    //     });
    // },
    getEmployees() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/employee`)
        .then((res) => {
          self.salesEmployees = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    // this.getTerritories();
    this.getEmployees();
  },
};
</script>

<style lang="scss" scoped>
</style>