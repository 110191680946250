<template>
  <v-container fluid>
    <!-- editor -->
    <editor :id="id" :initial="dataItem" :outlets="outlets" :activeRoute="activeRoute" @data="save"></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/call-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    activeRoute: {},
    id: null,
    itemsData: [],
    editMode: false,
    outlets:[],
  }),
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true,
    },
  },
  methods: {
    setId(val) {
      this.id = val;
      const self = this;
      this.$store
          .dispatch("get", `/routes/${val}`)
          .then((res) => {
            self.activeRoute = res;
            self.outlets = res.outlets;
          })
          .catch((err) => {
            console.log(err, "error");
            // this.$refs.snackbar.show(err, "red");
          });
    },
    save(data) {
        data.RouteCode = this.id;
      const self = this;
      const url = "/route_calls";
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push(`/routes/${self.id}/calls`);
        })
        .catch((err) => {
          console.log(err, "err");
          this.loader = false;
          // this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {}
};
</script>